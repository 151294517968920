import React, {useEffect, useState} from 'react';
import "./ReportScreen.css"
import {Button, Icon, Modal, Select, Divider, Header, Input, Loader, Dimmer, Step, Segment, Dropdown, Message} from "semantic-ui-react";
import {BACKEND_URL, JWT_KEY, YEAR_KEY} from "../../config";
import {getWithExpiry} from "../../services/localStorageService";
import {CSVLink} from "react-csv";
import _ from 'lodash';
import PlacesAutocomplete from '../PlacesAutocomplete'

const Report = () => {

  const [select, setSelect] = useState([]);
  const [downloadCSV, setDownloadCSV] = useState([]);
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [openNoResults, setOpenNoResults] = useState(false);
  const [nameReport, setNameReport] = useState({});
  const [vehicles, setVehicles] = useState(1);
  const [routesActive, setRoutesActive] = useState(true);
  const [routesCompleted, setRoutesCompleted] = useState(false);
  const [basketsActive, setBasketsActive] = useState(false);
  const [basketsCompleted, setBasketsCompleted] = useState(false);
  const [cardsActive, setCardsActive] = useState(false);
  const [cardsCompleted, setCardsCompleted] = useState(false);
  const [loadingBaskets, setLoadingBaskets] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [messages, setMessages] = useState({});
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [selectYear, setSelectYear] = useState([]);
  const [reportYear, setReportYear] = useState(getWithExpiry(YEAR_KEY))
  const [emailType, setEmailType] = useState('')
  const [financialReportFunctionName, setFinancialReportFunctionName] = useState();
  const [deliveryReportFunctionName, setDeliveryReportFunctionName] = useState();
  const [greetingsReportFunctionName, setGreetingsReportFunctionName] = useState();
  const [pekalachReportFunctionName, setPekalachReportFunctionName] = useState();
  const [paperReportFunctionName, setPaperReportFunctionName] = useState();

  useEffect(() => {
    fetch(`${BACKEND_URL}api/v1/orders/admin/years`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        var years = result.map((w) => ({value: w, text: w}))
        var currentYear = parseInt(getWithExpiry(YEAR_KEY))
        if (years.findIndex(item => item.value === currentYear) < 0) {
          years.push({value: currentYear, text: currentYear})
        }
        
        setSelectYear(years)
      })
      .catch((error) => console.log('No way! ', error));
  }, []);

  const didMount = React.useRef(false);

  useEffect(() => {
    if (!didMount.current) {
      didMount.current = true;
      return;
    }
    if(emailType) {
      getEmails(emailType)
    }
    
  }, [reportYear]);

  useEffect(() => {
    setRoutesActive(false);
  }, []);

  const emailSetSelect = {
    options: [
      {value: 'all', text: 'All'},
      {value: 'past_givers', text: 'Past Givers Not Giving Yet'},
      {value: 'basket_receivers', text: 'Basket Receivers'},
      {value: 'email_only', text: 'Email Only Customers'},
    ],
    selected: '',
  }

  const getEmails = (value) => {
    fetch(`${BACKEND_URL}api/v1/customer/admin/emails?type=${value}&year=${reportYear}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setSelect(result)
      })

      .catch((error) => console.log('No way! ', error));
  }

  const  generatePaperOrderNames = () => {
    fetch(`${BACKEND_URL}api/v1/customer/admin/paper`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        const paperNames = result.map(
          ({
             title,
             firstNames,
             lastName,
             addressLine1,
             addressLine2,
             addressLine3,
           }) => {
            return {
              title,
              firstNames,
              lastName,
              addressLine1,
              addressLine2: cleanUp(addressLine2),
              addressLine3,
            };
          }
        );
        sendReport(paperNames, "Paper_Order_Names.csv", "Paper Order Names")
      })

      .catch((error) => console.log('No way! ', error));
  }

  const generateAbandondedCarts = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/reports/customers/abandonedcarts`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        sendReport(result, "Abandoned_Carts.csv", "Abandoned Carts")
        setLoadingCards(false)
      })

      .catch((error) => console.log('No way! ', error));
  }

  const sendReport = (result, nameCSV, nameReport) => {
    if(result.length>0) {
      setDownloadCSV(result);
      setNameReport({nameCSV: nameCSV, nameReport: nameReport})
      setOpenMessageModal(true);
    } else {
      setOpenNoResults(true);
    }
  }

  const generateUnpaidReciprocated = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/reports/orders/reciprocated/unpaid?year=${reportYear}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        sendReport(result, "Unpaid_Reciprocated_Orders.csv", "Unpaid Reciprocated Orders")
        setLoadingCards(false);
      })

      .catch((error) => console.log('No way! ', error));
  }

  const generateUnpaidChecks = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/reports/orders/checks/unpaid?year=${reportYear}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        sendReport(result, "Unpaid_Check_Orders.csv", "Unpaid Check Orders")
        setLoadingCards(false);
      })

      .catch((error) => console.log('No way! ', error));
  }

  const generateOrderCountByCustomerReceiving = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/reports/orders/receiving/counts?year=${reportYear}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setLoadingCards(false);
        sendReport(result, "Order_Count_By_Customer_Receiving.csv", "Order Count By Customer Receiving")
      })

      .catch((error) => console.log('No way! ', error));
  }

  const getRouteResults = async () => {

    const response = await fetch(
      // `${BACKEND_URL}api/v1/route/simple/results`,
      `${BACKEND_URL}api/v1/route/optimized/results`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      }
    ).catch((err) => {
      throw new Error(err);
    });

    if (!response.ok) {
      const {status} = response;
      if (status === 425) {
        return {notReady: true};
      }
      throw new Error(await response.text());
    }
    const routeResults = await response.json();
    return {routeResults};
  };

  const generateRoutesReport = async () => {
    setLoadingCards(true)
    const response = await fetch(
      // `${BACKEND_URL}api/v1/route/simple?year=${reportYear}`,
      `${BACKEND_URL}api/v1/route/optimized?numVehicles=${vehicles}&year=${reportYear}&startLon=${lng}&startLat=${lat}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      }
    ).catch((err) => {
      throw new Error(err);
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
    console.log(response)
  };

  const getRoutesReport = () => {
    if(lat === 0) {
      return;
    }
    setLoadingCards(true)
    generateRoutesReport().then(async () => {
      let {routeResults, notReady} = await getRouteResults();
      if (notReady) {
        console.log('Not ready yet...');
        const intervalId = setInterval(async () => {
          let {
            routeResults: repeatedRouteResults,
            notReady: repeatedNotReady,
          } = await getRouteResults();
          if (repeatedNotReady) {
            console.log('Not ready yet...');
            return;
          }
          console.log('Ready to begin generating routes!!!');
          clearInterval(intervalId);
          setDownloadCSV(
            repeatedRouteResults.routes[0].steps
          );
          setNameReport({nameCSV: "Routes.csv", nameReport: "Routes"})
          setOpenMessageModal(true);
          setRoutesCompleted(true);
          setBasketsActive(true);
          setCardsActive(true);
          setLoadingCards(false)
        }, 3000);
        return routeResults
      } else {
        setLoadingCards(false)
        setDownloadCSV(
          // routeResults
          routeResults.routes[0].steps
        );
        setNameReport({nameCSV: "Routes.csv", nameReport: "Routes"})
        setOpenMessageModal(true);
        setRoutesCompleted(true);
        setBasketsActive(true);
        setCardsActive(true);
        setLoadingCards(false)
      }
    })
  }

  const generateRoutes = () => {
    fetch(`${BACKEND_URL}api/v1/route/optimized?numVehicles=${vehicles}&year=${reportYear}&startLon=${lng}&startLat=${lat}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })    
    .catch(error => {                       
      console.log('Request failed', error);
    });
  };

  const  generateCustomerNames = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/customer/names`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setLoadingCards(false);
        sendReport(result, "Customer_Names.csv", "Customer Names")
      })

      .catch((error) => console.log('No way! ', error));
  }

  const  generateGreetings = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/customer/admin/greetings`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setLoadingCards(false);
        sendReport(result, "greetings_and_addresses.csv", "Greetings and Addresses")
      })

      .catch((error) => console.log('No way! ', error));
  }

  const  generateCustomers = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/customer/admin`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      // .then((r) => _.omit(r), 'customerId')
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setLoadingCards(false);
        const final = _.map(result, function(r){ 
          return _.omit(r, 'customerId', 'orgId', 'createdAt', 'updatedAt', 'titleId', 'streetId', 'cityId', 'neighborhoodId', 
          'stateId', 'newPassword', 'defaultGreeting', 'greetingSuffix', 'greetingSuffixes', 'deactivatedOn', 'stripeCustomerId',
          'googlePlaceId', 'osmId', 'customId', 'latitude', 'longitude', 'staffCategoryIds', 'year', 'cart'); });
        sendReport(final, "customers.csv", "All Customers")
      })

      .catch((error) => console.log('No way! ', error));
  }

  const  generatePekalachOrders = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/item/orders/consolidated?year=${reportYear}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setLoadingCards(false);
        sendReport(result, "pekalach_orders.csv", "Pekalach Orders")
      })

      .catch((error) => console.log('No way! ', error));
  }

  const  generateTotalPekalach = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/reports/count/items/receiving?year=${reportYear}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        if(result.length>0) {
          setLoadingCards(false);
          setDownloadCSV(Object.entries(result));
          setNameReport({nameCSV: "total_pekalach.csv", nameReport: "Total Pekalach"})
          setOpenMessageModal(true);
        } else {
          setOpenNoResults(true);
        }
      })

      .catch((error) => console.log('No way! ', error));
  }

  function pivot(input_arr) { 
    var id_year = {};   
    var years = {};
    var id_other_columns = {}

    for(let i = 0; i < input_arr.length; i++) {
        var row = input_arr[i];
        var customerId = row.customerId;
        if(!id_year[customerId]) {
          id_year[customerId] = {};
        }
        id_year[customerId][row.year] = row.amount
        if(!years[row.year]) {
          years[row.year] = true;
        }
        if(!id_other_columns[customerId]) {
          id_other_columns[customerId] = {};
        }
        id_other_columns[customerId] = [row.name, row.firstNames, row.lastName, row.phone, row.email]
    }

    var years_in_order = Object.keys(years).sort();
    var header = ['Name', 'FirstNames', 'LastName', 'Phone', 'Email']
    years_in_order.map(function(year) {
      header.push(year);
    })
    header.push('Total')
    var rows = [header,]
    var sorted_ids = Object.keys(id_year).sort();

    sorted_ids.map(function(id) {
      var new_row = [];
      var total = 0;
      id_other_columns[id].map(function(other) {
        new_row.push(other)
      })
      
      years_in_order.map(function(year) {
        new_row.push(id_year[id][year] || 0);
        total = total + (id_year[id][year] || 0);
      }) 
      new_row.push(total);
      rows.push(new_row);
    })
    return rows;
  }

  const  generateSummedOrders = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/reports/orders/summed`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        setLoadingCards(false);
        if(result.length>0) {
          setDownloadCSV(pivot(result));
          setNameReport({nameCSV: 'consolidated_givers.csv', nameReport: 'Consolidated Givers'})
          setOpenMessageModal(true);
        } else {
          setOpenNoResults(true);
        }
      })

      .catch((error) => console.log('No way! ', error));
  }

  const  generateCustomerComplete = () => {
    setLoadingCards(true);
    fetch(`${BACKEND_URL}api/v1/reports/customers/givinggetting`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        sendReport(result,  "customer_complete.csv", "Customer Complete")
        setLoadingCards(false)
      })

      .catch((error) => console.log('No way! ', error));
  }

  const generateBasketGreetings = async () => {
    setLoadingBaskets(true)
    const response = await fetch(
      `${BACKEND_URL}api/v1/reports/orders/receiving/large?year=${reportYear}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      }
    ).catch((err) => {
      throw new Error(err);
    });
    setLoadingBaskets(false)
    if (!response.ok) {
      throw new Error(await response.text());
    }
    const basketGreetings = await response.json();
    return basketGreetings;
  };

  const cleanUp = (val) => (val ? val : '');

  const highestGreetingsNumber = (basketGreetings) => {
    basketGreetings.reduce(
      (accum, {greetings}) => {

        if (!greetings) {
          return accum;
        }

        const numberOfGreetings = greetings.split('\n').length;
        return accum <= numberOfGreetings ? numberOfGreetings : accum;
      },
      0
    );
    return highestGreetingsNumber;
  };

  const padGreetingsMap = (originalGreetingsMap, mostGreetingsNumber) => {
    const paddedGreetingsMap = {...originalGreetingsMap};
    const greetingsNumsArray = Array.from(
      Array(mostGreetingsNumber).keys(),
      (x) => x + 1
    );

    greetingsNumsArray.forEach((num) => {
      const key = `greeting${num}`;
      const alreadyThere = paddedGreetingsMap[key];
      if (!alreadyThere) {
        paddedGreetingsMap[key] = '';
      }
    });
    return paddedGreetingsMap;
  };

  const generateGreetingsCSV = (customers) => {
    const mostGreetings = highestGreetingsNumber(customers);
    const greetingsCSV = customers.map(
      ({
         firstNames,
         lastName,
         name,
         addressLine1,
         addressLine2,
         addressLine3,
         emailOnly,
         neighborhood,
         routeId,
         routeStopNumber,
         greetings,
       }) => {
        let greetingsMap = {};
        if (greetings) {
          greetingsMap = greetings
            .split('\n')
            .reduce((accum, currGreeting, currIndex) => {
              accum[`greeting${currIndex + 1}`] = currGreeting;
              return accum;
            }, {});
        }
        greetingsMap = padGreetingsMap(greetingsMap, mostGreetings);
        return {
          firstNames,
          lastName,
          name,
          addressLine1,
          addressLine2: cleanUp(addressLine2),
          addressLine3,
          emailOnly,
          neighborhood,
          routeId,
          routeStopNumber,
          ...greetingsMap,
        };
      }
    );
    setDownloadCSV(greetingsCSV);
    setOpenMessageModal(true);
  };

  const generateCardGreetings = async () => {
    setLoadingCards(true)
    const response = await fetch(
      `${BACKEND_URL}api/v1/reports/orders/receiving/small?year=${reportYear}`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      }
    ).catch((err) => {
      throw new Error(err);
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
    console.log(response)
  };

  const getCardResults = async () => {

    const response = await fetch(
      `${BACKEND_URL}api/v1/reports/orders/receiving/small/results`,
      {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        },
      }
    ).catch((err) => {
      throw new Error(err);
    });

    if (!response.ok) {
      const {status} = response;
      if (status === 425) {
        return {notReady: true};
      }
      throw new Error(await response.text());
    }
    const cardGreetings = await response.json();
    return {cardGreetings};
  };

  const getBasketsReport = () => {
    generateBasketGreetings()
      .then((basketGreetings) => 
        generateGreetingsCSV(basketGreetings));
    setNameReport({nameCSV: "Baskets-report.csv", nameReport: "Baskets report"})
    setBasketsCompleted(true)
    setCardsActive(true)
  }

  const getCardsReport = () => {
    generateCardGreetings().then(async () => {
      let {cardGreetings, notReady} = await getCardResults();
      if (notReady) {
        console.log('Not ready yet...');
        const intervalId = setInterval(async () => {
          let {
            cardGreetings: repeatedCardGreetings,
            notReady: repeatedNotReady,
          } = await getCardResults();
          if (repeatedNotReady) {
            console.log('Not ready yet...');
            return;
          }
          console.log('Ready to begin generating card greetings!!!');
          // sendReport(repeatedCardGreetings, "Cards.csv", "Cards")
          setCardGreetings(repeatedCardGreetings);
          clearInterval(intervalId);
        }, 3000);
        return cardGreetings
      } else {
        // sendReport(cardGreetings, "Cards.csv", "Cards")
        setCardGreetings(cardGreetings);
      }
    })
  }

  const setCardGreetings = (cardGreetings) => {
    setNameReport({nameCSV: "cardGreetings.csv", nameReport: "Cards report"})
        generateGreetingsCSV(cardGreetings);
        setLoadingCards(false)
        setCardsCompleted(true);
  }

  const handleEmailSelectChange = (event, {value}) => {
    setEmailType(value)
    getEmails(`${value}`);
  }

  const handleInputChange = e => {
    if (isNaN(e.target.value)) {
      return;
    } else {
      setVehicles(Number(e.target.value));
    }
  };

  const steps = [
    {
      key: 'routes',
      icon: 'file excel',
      title: 'Routes',
      description: 'Choose a starting address above, then click on this.',
      onClick: () => getRoutesReport(),
      active: routesActive,
      disabled: !routesActive,
      completed: routesCompleted
    },
    {
      key: 'baskets',
      icon: 'file excel',
      title: 'Baskets',
      description: 'Generate Baskets Report',
      onClick: () => getBasketsReport(),
      active: basketsActive,
      disabled: !basketsActive,
      completed: basketsCompleted
    },
    { 
      key: 'cards',
      icon: 'file excel',
      title: 'Cards',
      description: 'Generate Cards Report',
      onClick: () => getCardsReport(),
      active: cardsActive,
      disabled: !cardsActive,
      completed: cardsCompleted
    } 
  ]

  // const reset = () => {
  //   setVehicles(1)
  //   setRoutesActive(true)
  //   setRoutesCompleted(false)
  //   setBasketsActive(false)
  //   setBasketsCompleted(false)
  //   setCardsActive(false)
  //   setCardsCompleted(false)
  // }

  const greetingsOptions = [
    {
      key: 'generateCustomers',
      text: 'All Customers',
      value: 'All information about all customers'
    },
    {
      key: 'generateGreetings',
      text: 'All Greetings and Addresses',
      value: 'A quick way to look at all greetings and addresses in the system'
    }
  ]

  const deliveryOptions = [
    {
      key: 'generateBaskets',
      text: 'Baskets Report',
      value: 'Generate basket reports'
    },
    {
      key: 'generateCards',
      text: 'Cards Report',
      value: 'Generate card reports'
    }
  ]

  const pekalachOptions = [
    {
      key: 'generatePekalachOrders',
      text: 'All Pekalach Orders',
      value: 'Download a list of who bought which pekalach and in what quantity.'
    },
    {
      key: 'generateTotalPekalach',
      text: 'Total Pekalach Orders',
      value: 'The total amount of pekalach that have been ordered and need to be made grouped by each item.'
    }
  ]

  const financialReportOptions = [
    {
      key: 'generateUnpaidReciprocated',
      text: 'Unpaid Reciprocated Orders',
      value: 'A report of all reciprocated orders that are currently unpaid.'
    },
    {
      key: 'generateUnpaidChecks',
      text: 'Unpaid Check Orders',
      value: 'A report of all check orders that are currently unpaid.'
    },
    {
      key: 'generateSummedOrders',
      text: 'Givers With Amounts',
      value: 'Generate a report of everyone who is giving and who gave in previous years with amounts paid, phones and emails.'
    },
    {
      key: 'generateCustomerComplete',
      text: 'Customers Giving and Receiving',
      value: 'Complete report of all customers with amounts giving and receiving as well as counts.'
    },
    {
      key: 'generateAbandondedCarts',
      text: 'Abandoned Carts',
      value: 'Generate a report of all customers who have abandoned their carts without checking out.'
    },
    {
      key: 'generateOrderCountByCustomerReceiving',
      text: 'Order Counts by Customer Receiving',
      value: 'Generate a report of how many people are giving to each customer sorted by the top receivers.'
    },
  ]

  const paperReportOptions = [
    {
      key: 'generatePaperOrderNames',
      text: 'Paper Order Names',
      value: 'Generate a list of names of people that need to receive paper order sheets.'
    },
    {
      key: 'generateCustomerNames',
      text: 'Paper Order Sheets',
      value: 'Print all the names in the database for use of paper order sheets.'
    }
  ]

  const executeFunctionByName = (functionName) => {
    eval(functionName).call();
  }

  const handleFinancialDropDownChange = (e, data) => {
    var message = messages;
    const value = data.value;
    const {key} = data.options.find(o => o.value === value);
    message.financial = value;
    setMessages(message);
    switch(key) {
      case "generateUnpaidReciprocated": setFinancialReportFunctionName('generateUnpaidReciprocated'); break;
      case "generateUnpaidChecks": setFinancialReportFunctionName('generateUnpaidChecks'); break;
      case "generateSummedOrders": setFinancialReportFunctionName('generateSummedOrders'); break;
      case "generateCustomerComplete": setFinancialReportFunctionName('generateCustomerComplete'); break;
      case "generateAbandondedCarts": setFinancialReportFunctionName('generateAbandondedCarts'); break;
      case "generateOrderCountByCustomerReceiving": setFinancialReportFunctionName('generateOrderCountByCustomerReceiving'); break;
    }
  };

  const handleDeliveryDropDownChange = (e, data) => {
    var message = messages;
    const value = data.value;
    const {key} = data.options.find(o => o.value === value);
    message.delivery = value;
    setMessages(message);
    switch(key) {
      case "generateBaskets": setDeliveryReportFunctionName('getBasketsReport'); break;
      case "generateCards": setDeliveryReportFunctionName('getCardsReport'); break;
    }
  };

  const handleGreetingsDropDownChange = (e, data) => {
    var message = messages;
    const value = data.value;
    const {key} = data.options.find(o => o.value === value);
    message.greetings = value;
    setMessages(message);
    switch(key) {
      case "generateGreetings": setGreetingsReportFunctionName('generateGreetings'); break;
      case "generateCustomers": setGreetingsReportFunctionName('generateCustomers'); break;
    }
  };

  const handlePekalachDropDownChange = (e, data) => {
    var message = messages;
    const value = data.value;
    const {key} = data.options.find(o => o.value === value);
    message.pekalach = value;
    setMessages(message);
    switch(key) {
      case "generatePekalachOrders": setPekalachReportFunctionName('generatePekalachOrders'); break;
      case "generateTotalPekalach": setPekalachReportFunctionName('generateTotalPekalach'); break;
    }
  };

  const handlePaperDropDownChange = (e, data) => {
    var message = messages;
    const value = data.value;
    const {key} = data.options.find(o => o.value === value);
    message.paper = value;
    setMessages(message);
    switch(key) {
      case "generatePaperOrderNames": setPaperReportFunctionName('generatePaperOrderNames'); break;
      case "generateCustomerNames": setPaperReportFunctionName('generateCustomerNames'); break;
    }
  };

  const yearSetSelect = {
    options: selectYear, 
    selected: "",
  }

  const handleYearChange = (event, {value}) => {
    setReportYear(value);
  }

  const handleChangeAddress = ({lat, lng}) => {
    setLat(lat);
    setLng(lng);
    setRoutesActive(true);
  };

  return (
    <div className={"content-table"}>
      <div>
        <Message 
          color='green'
          floating
        >
          <Message.Header>
            Choose a Year
          </Message.Header>
          <Select
              defaultValue={getWithExpiry(YEAR_KEY)}
              options={yearSetSelect.options}
              placeholder='Select year'
              onChange={handleYearChange}
            />
        </Message>
        <Divider horizontal>
          <Header as='h4'>
            <Icon name='car' />
            Delivery Reports
          </Header>
        </Divider>

        
        {/* <PlacesAutocomplete
          onAddressChange={handleChangeAddress}
          label='Starting Address'
          placeholder='Start entering a starting address'
        /> */}

        {/* <Input type="number"
              value={vehicles}
              onChange={handleInputChange}
        /> */}
        {/* <Button
          onClick={reset}
          color='blue'>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
              Reset
            </span>
          </div>
        </Button> */}
        {/* <Step.Group 
          fluid vertical
          items={steps} 
        /> */}
        {(loadingCards || loadingBaskets) && 
          <Dimmer active inverted>
            <Loader active content='Retrieving Data' inverted size='massive'/>
          </Dimmer>
        }
      </div>
    
      <div className={"submit-button"}>
        <Message 
          color='red'
          floating
        >
          <Message.Header>
            IMPORTANT
          </Message.Header>
          Before printing delivery reports, you must first go to the routes page and generate the routes. The delivery reports will then be in the proper order.
        </Message>
        <Dropdown 
          placeholder='Select a Report'
          fluid
          selection
          options={deliveryOptions}
          onChange={handleDeliveryDropDownChange}
        />
        {messages.delivery && 
          <Segment className='report-segment' raised size='large' >{messages.delivery}</Segment>
        }
        <Button
          onClick={() => executeFunctionByName(deliveryReportFunctionName)}
          color='green'
          >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon size="large" name="file excel"/>
            <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
            Submit
            </span>
          </div>
        </Button>
        
      </div>

      <Divider horizontal>
        <Header as='h4'>
          <Icon name='mail' />
          Email Addresses
        </Header>
      </Divider>
      <div className={"email-addresses"}>
        <Select
          options={emailSetSelect.options}
          placeholder='Select from the list'
          onChange={handleEmailSelectChange}
        />
        <span style={{paddingTop: "2px"}}>
          {select.length + " records "} <br />
          {select.length ? null : "Select a choice from the list"}
        </span>
        <CSVLink style={select.length ? {color: "grey"} : {pointerEvents: "none", cursor: "default"}}
                 data={select} filename={"Email.csv"}>
          <Button

            basic
            color={select.length ? "green" : "grey"}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon size="large" name="file excel"/>
              <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
							Download
				    </span>
            </div>
          </Button>
        </CSVLink>
      </div>

      <Divider horizontal>
        <Header as='h4'>
          <Icon name='copy outline' />
          Paper Orders
        </Header>
      </Divider>
      <div className={"submit-button"}>
        <Dropdown 
          placeholder='Select a Report'
          fluid
          selection
          options={paperReportOptions}
          onChange={handlePaperDropDownChange}
        />
        {messages.paper && 
          <Segment className='report-segment' raised size='large' >{messages.paper}</Segment>}
        <Button
          onClick={() => executeFunctionByName(paperReportFunctionName)}
          color='green'
          >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon size="large" name="file excel"/>
            <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
            Submit
            </span>
          </div>
        </Button>
      </div>

      <Divider horizontal>
        <Header as='h4'>
          <Icon name='ordered list' />
          Orders
        </Header>
      </Divider>
      <div className={"submit-button"}>
        <Dropdown 
          placeholder='Select a Report'
          fluid
          selection
          options={financialReportOptions}
          onChange={handleFinancialDropDownChange}
        />
        {messages.financial && 
          <Segment className='report-segment' raised size='large' >{messages.financial}</Segment>
        }
        <Button
          onClick={() => executeFunctionByName(financialReportFunctionName)}
          primary color='green'
          >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon size="large" name="file excel"/>
            <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
            Submit
            </span>
          </div>
        </Button>
      </div>

      <Divider horizontal>
        <Header as='h4'>
          <Icon name='users' />
          Customers
        </Header>
      </Divider>
      <div className={"submit-button"}>
        <Dropdown 
          placeholder='Select a Report'
          fluid
          selection
          options={greetingsOptions}
          onChange={handleGreetingsDropDownChange}
        />
        {messages.greetings && 
          <Segment className='report-segment' raised size='large' >{messages.greetings}</Segment>
        }
        <Button
          onClick={() => executeFunctionByName(greetingsReportFunctionName)}
          color='green'
          >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon size="large" name="file excel"/>
            <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
            Submit
            </span>
          </div>
        </Button>
      </div>

      {/* <Divider horizontal>
        <Header as='h4'>
          <Icon name='table' />
          Pekalach
        </Header>
      </Divider>
      <div className={"submit-button"}>
        <Dropdown 
          placeholder='Select a Report'
          fluid
          selection
          options={pekalachOptions}
          onChange={handlePekalachDropDownChange}
        />
        <Segment className='report-segment' raised size='large' >{messages.pekalach}</Segment>
        <Button
          onClick={() => executeFunctionByName(pekalachReportFunctionName)}
          primary color='green'
          >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Icon size="large" name="file excel"/>
            <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
            Submit
            </span>
          </div>
        </Button>
      </div> */}

      <Modal
        size={"mini"}
        open={openMessageModal}
        onClose={() => setOpenMessageModal(false)}
        onOpen={() => setOpenMessageModal(true)}
      >
        <Modal.Header>{nameReport.nameReport}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {nameReport.nameReport} successfully generated!
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color={"red"} onClick={() => setOpenMessageModal(false)}>Cancel</Button>
          <CSVLink data={downloadCSV} filename={nameReport.nameCSV}>
            <Button style={{marginLeft: '15px'}} basic color={"green"}
                    onClick={() => setOpenMessageModal(false)}>Download</Button>
          </CSVLink>
        </Modal.Actions>
      </Modal>

      <Modal
        size={"mini"}
        open={openNoResults}
        onClose={() => setOpenNoResults(false)}
        onOpen={() => setOpenNoResults(true)}
      >
        <Modal.Header>No Results</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            There are no results!
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button basic color={"red"} onClick={() => setOpenNoResults(false)}>Close</Button>
        </Modal.Actions>
      </Modal>
      
    </div>
  );
};

export default Report;