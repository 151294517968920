// import runtimeEnv from '@mars/heroku-js-runtime-env';

// const { REACT_APP_BACKEND_URL, REACT_APP_FRONTEND_URL, REACT_APP_ENVIRONMENT } = runtimeEnv();

require('dotenv').config()
const REACT_APP_BACKEND_URL= `${process.env.REACT_APP_BACKEND_URL}`;
const REACT_APP_ENVIRONMENT= `${process.env.REACT_APP_ENVIRONMENT}`;
const REACT_APP_FRONTEND_URL= `${process.env.REACT_APP_FRONTEND_URL}`;

export const BACKEND_URL =
  REACT_APP_BACKEND_URL || 'https://ezpurim-api.herokuapp.com/';

export const ENVIRONMENT = REACT_APP_ENVIRONMENT || 'local';
export const FRONTEND_URL = REACT_APP_FRONTEND_URL || 'https://ezpurim.herokuapp.com';

export const JWT_KEY = `ezp.admin.jwt.${ENVIRONMENT}`;
export const YEAR_KEY = `ezp.admin.active.year.${ENVIRONMENT}`;
