import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import axios from "axios";
import { Button, Modal, Dimmer, Loader, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import { BACKEND_URL, JWT_KEY, YEAR_KEY } from "../../config";
import { getWithExpiry } from "../../services/localStorageService";
import "./RouteScreen.css";
import PlacesAutocomplete from '../PlacesAutocomplete'

const RouteReport = () => {
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [lat, setLat] = useState('');
    const [lng, setLng] = useState('');

    useEffect(() => {
        fetchItems();
    }, []);

    const fetchItems = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${BACKEND_URL}api/v1/route/stored?year=${getWithExpiry(YEAR_KEY)}`, {
                headers: {
                    'Authorization': `Bearer ${getWithExpiry(JWT_KEY)}`,
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip',
                }
            });
            setItems(response.data.routes[0].steps);
        } catch (error) {
            console.error("Error fetching items:", error);
        } finally {
            setLoading(false);
        }
    };
    

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        const reorderedItems = [...items];

        if (selectedItems.size === 1 && selectedItems.has(items[source.index].id)) {
            // Dragging a single selected item
            const [movedItem] = reorderedItems.splice(source.index, 1);
            reorderedItems.splice(destination.index, 0, movedItem);
        } else {
            // Dragging multiple selected items
            const selectedIds = new Set(selectedItems);
            const movedItems = items.filter(item => selectedIds.has(item.id));

            // Removing selected items from their original positions
            movedItems.forEach(item => {
                const index = reorderedItems.findIndex(i => i.id === item.id);
                reorderedItems.splice(index, 1);
            });

            // Inserting them at the new destination
            if(destination.index > source.index) {
                reorderedItems.splice(destination.index-movedItems.length+1, 0, ...movedItems);
            } else {
                reorderedItems.splice(destination.index, 0, ...movedItems);
            }
        }

        setItems(reorderedItems);
    };
    
    const handleItemClick = (id) => {
        const newSelectedItems = new Set(selectedItems);
        if (newSelectedItems.has(id)) {
            newSelectedItems.delete(id);
        } else {
            newSelectedItems.add(id);
        }
        setSelectedItems(newSelectedItems);
    };

    const fetchResults = async () => {
        setLoading(true);
        try {
            await axios.get(`${BACKEND_URL}api/v1/route/optimized?numVehicles=1&year=${getWithExpiry(YEAR_KEY)}&startLon=${lng}&startLat=${lat}`, {
                headers: {
                    'Authorization': `Bearer ${getWithExpiry(JWT_KEY)}`,
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip',
                }
            });

            let status = 425;
            while (status === 425) {
                let errStatus = 0;
                const resultResponse = await axios.get(`${BACKEND_URL}api/v1/route/optimized/results`, {
                    headers: {
                        'Authorization': `Bearer ${getWithExpiry(JWT_KEY)}`,
                        'Content-Type': 'application/json'
                    }
                }).catch((err) => {
                    errStatus = err.response.status;
                });
                if(errStatus === 425) {
                    await new Promise(resolve => setTimeout(resolve, 1000));
                } else if (resultResponse.status === 200) {
                    setItems(resultResponse.data.routes[0].steps);
                    status = resultResponse.status;
                }
            }
            toast.success("Routes successfully generated! Remember to save.");
        } catch (error) {
            console.error("Error fetching results:", error);
        } finally {
            setLoading(false);
            setModalOpen(false);
        }
    };

    const saveItems = async () => {
        setLoading(true);
        const dataToSave = items.map((item, index) => ({
            customerId: item.id,
            routeStopNumber: index + 1,
        }));

        try {
            await axios.put(`${BACKEND_URL}api/v1/customer/routes`, dataToSave, {
                headers: {
                    'Authorization': `Bearer ${getWithExpiry(JWT_KEY)}`,
                    'Content-Type': 'application/json',
                    'Accept-Encoding': 'gzip',
                }
            });
            toast.success("Routes saved successfully!");
        } catch (error) {
            toast.error("Error saving items.");
            console.error("Error saving items:", error);
        } finally {
            setLoading(false);
        }
    };

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).join(",");
        const rows = data.map(item => Object.values(item).join(",")).join("\n");
        return `${headers}\n${rows}`;
    };

    const downloadCSV = () => {
        const csv = convertToCSV(items);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'items.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    };

    const handleChangeAddress = ({lat, lng}) => {
        setLat(lat);
        setLng(lng);
    };

    const showModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    return (
        <div>
            <Dimmer active={loading} page>
                <Loader size="massive">Loading</Loader>
            </Dimmer>
            <div className="submit-button"> 
                <div className="autocomplete-wrapper">
                    <PlacesAutocomplete
                        onAddressChange={handleChangeAddress}
                        label='Starting Address'
                        placeholder='Start entering a starting address'
                    />
                </div>
                <Button onClick={showModal} disabled={loading || !lat} color="red"> 
                    <Icon size="large" name="play" />
                    <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
                        {loading ? "Loading..." : "Generate Route"} 
                    </span>
                </Button> 
                <Button onClick={saveItems} primary> 
                    <Icon size="large" name="save" />
                    <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
                    Save 
                    </span>
                </Button> 
                <Button onClick={downloadCSV} color="green">
                    <Icon size="large" name="file excel" />
                    <span style={{fontWeight: 'bold', marginLeft: '5px'}}>
                    Download 
                    </span>
                </Button> 
            </div>
            <Modal open={modalOpen} onClose={closeModal} size="small">
                <Modal.Header>Warning</Modal.Header>
                <Modal.Content>
                    <p>This will recalculate all the routes. If you rearranged any routes, everything you did will be lost. However, nothing will be saved until you click save. 
                        <br/>Are you sure you want to do this?</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={closeModal} negative>Cancel</Button>
                    <Button onClick={fetchResults} positive>Yes</Button>
                </Modal.Actions>
            </Modal>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="items">
                    {(provided) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ width: "500px", margin: "auto" }}
                        >
                            {items.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id.toString()}
                                    index={index}
                                    isDragDisabled={!selectedItems.has(item.id) && selectedItems.size > 0}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onClick={() => handleItemClick(item.id)}
                                            style={{
                                                padding: "16px",
                                                margin: "4px 0",
                                                backgroundColor: selectedItems.has(item.id) ? "#d0f0c0" : "#f0f0f0",
                                                borderRadius: "4px",
                                                border: selectedItems.has(item.id) ? "2px solid #00aaff" : "none",
                                                ...provided.draggableProps.style,
                                                cursor: selectedItems.has(item.id) ? 'move' : 'pointer'
                                            }}
                                        >
                                            {item.address}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default RouteReport;
